
export class CommonUtil {

    static valueExist (val: any): boolean {

      
        let existValue = true;
        if (val == undefined || val == "" || val == '' ) {
            existValue = false;
        }
        return   val === 0 && !existValue ?  true : existValue;        
    }

    static getOrDefault (expectedValue: any , defaultValue: any): any {     
        return this.valueExist(expectedValue) ? expectedValue: defaultValue;
    }

    static addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        console.log(date.getDate);
        return date;
    }

    static isDatesEqual(date1: Date, date2: Date): boolean {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    static convertUTCDateToLocalDate(input: Date| any) {
        const offset = input.getTimezoneOffset();
        if (offset < 0) {
            input.setHours(12, 0, 0);
        }
        return input;
    }
   
    static toDecimalNumber(numString: string, precision: number): string {
        if(!numString.indexOf(".")){
          numString = numString+'.' + '0'.repeat(precision);
        }
        const num = parseFloat(numString);
        if (!isNaN(num)) {    
            return (Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)).toFixed(precision);
        }
        return numString;
      }

     static holidayFilter (date: Date | null, holidays: Date[] | []): boolean  {     
      if (date && holidays.length) {
        for (let holiday of holidays) {
          if (CommonUtil.isDatesEqual(holiday, date)) {
            return true;
          }
        }       
      };
      return false;
    }
  
}
