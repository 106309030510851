export function debounce(delay: number = 500 ) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        let timer: ReturnType<typeof setTimeout> ;
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                originalMethod.apply(this, args);
            }, delay);
        };
        return descriptor;
    };
}