import { Account } from "./account.model";

export class TransferRequest {

    private transferDate: string | null;
    private valueDate: string | null;
    private amount: number;
    private fromAccount: Account;
    private toAccount: Account;
    private fromBalance: number;
    private toBalance: number;
    private comment: string;
        
    constructor(transferDate: string | null, amount: number, fromAccount: Account, fromBalance: number, toAccount: Account, toBalance: number, comment: string) {
        this.transferDate = transferDate;
        this.valueDate = transferDate;
        this.amount = amount;
        this.fromAccount = fromAccount;
        this.toAccount = toAccount;  
        this.fromBalance = fromBalance;
        this.toBalance = toBalance;  
        this.comment = comment;
      }
}
