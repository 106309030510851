import { CommonUtil } from './../../utilities/common-util';
import { Injectable } from '@angular/core';
import { TransmitUiHandler } from './transmit-ui-handler';
import { from, Observable } from 'rxjs';
import { sharedState } from '@citizens/mfe-shared-state';
import { environment } from '../../../environments/environment';
import { ThreatMetrixService } from './tmx.service';

@Injectable({
  providedIn: 'root'
})
export class TransmitService {
  public transmitSDK!: com.ts.mobile.sdk.TransmitSDKXm;

  constructor(private ssoUiHandler: TransmitUiHandler,
    private threatMetrixService: ThreatMetrixService) {
    sharedState.getApplicationDataSub('transmitInstance').subscribe(data => {
      console.log(data);
      this.transmitSDK = data.sdk;
    });
  }

  initializeTransmit(): Observable<boolean> {
    const transmitUrl = environment.transmitUrl;
    const appName = 'web_universal_portal';
    const tokenName = undefined;
    const token = undefined;
    // return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token );

    const settings = this.getTransmitConnectionSettings(transmitUrl, appName);
    this.transmitSDK.setConnectionSettings(settings);

    this.transmitSDK.setUiHandler(this.ssoUiHandler as any); // Using 'any' type due to type definition limitations
    return from(
      this.transmitSDK.initialize().then((result) => {
        return result;
      })
    );
  }

  getCurrentSessionId(value?: string): string {
    return this.threatMetrixService.initThreatMetrixApiService(CommonUtil.getOrDefault(value, 'Univesal'));
  }

  getTransmitConnectionSettings(serverUrl: any, applicationName: any) {
    const appName = applicationName;
    const tokenName = '';
    const token = '';
    return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token);
  }

  authenticate(universalId: any, profilingDetails: any, tmxUnivSessionId: any): Promise<any> {
    return this.login(universalId,
      profilingDetails,
      environment.transmitJourneyName,
      tmxUnivSessionId,
      environment.threatMetrixProfileUrl);
  }

  async login(universalId: any, profilingDetails: any, journeyName: string | null, tmxUnivSessionId: any, profiledUrl: any): Promise<com.ts.mobile.sdk.AuthenticationResult> {
    const clientContext = {};
    const additionalParams = {
      'universal_id': universalId,
      'profiling_details': profilingDetails,
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl
    };

    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }
}
