import { FormControl, Validators } from "@angular/forms";
import { CommonUtil } from "./common-util";

export class ValidatiorUtil extends Validators {

    static readonly futureDateSelect = { invalid: 'Please choose a non-holiday date' };
    static readonly dateRequired = { invalid: 'Transfer date is required' };
    static readonly previousDateCheck = { invalid: 'Transfer date cannot be previous day' }

    static readonly amountRequired = { invalid: 'Amount is required' };
    static readonly amountInvalid = { invalid: 'Amount entered is invalid' };
    static readonly amountAtleast = { invalid: 'Amount must be atleast 0.01' };
    static readonly amountMaxLength = { invalid: 'Amount exceeds the 15 characters limit' };
    static readonly amountMaxNumber = { invalid: 'Amount cannot exceed 999999999999' };
    static readonly amountPattern = /^-?[\d.]+(?:e-?\d+)?$/;

    static readonly accountRequired = { invalid: 'Please select account' };
    static readonly accountInvalid = { invalid: 'Account is invalid' };
    /**
    * Validator for the Date field, check input date is not smaller than today's date and is mandatory field
    * @param control form control to validate the element
    * @returns form control with errors object in array
    */
    static dateValidator(control: any) {
        control.markAsTouched();
        if (control.errors?.matDatepickerFilter) {
            control.setErrors(ValidatiorUtil.futureDateSelect);
            return control.errors;
        }      
        let errorMessage = !CommonUtil.valueExist(control.value) ? ValidatiorUtil.dateRequired :  ValidatiorUtil.previousDayValidator(control);        
        // return null if there's no errors
        control.setErrors(errorMessage);
        return control.errors;

       
    }

    static previousDayValidator(control: FormControl) {
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);
        const compareDate: Date = CommonUtil.getOrDefault(control.value, new Date());
        compareDate.setHours(0, 0, 0, 0);
        return (compareDate < today) ? ValidatiorUtil.previousDateCheck : null;       
    }
    /**
     * Validator for the Amount field, check amount pattern, the Amount is required and must be atlease 0.01
     * @param control form control to validate the element
     * @returns form control with errors object in array
     */
    static amountValidator(control: any) {
        control.setErrors(null);
        if (control.touched || control.dirty) {
            let errorMessage = ValidatiorUtil.isValidAmountCheck(control)
            control.setErrors(errorMessage);
        }
        return control.errors;
    }

    /**
     * Validator for the Account, the Account is required and must valid account
     * @param control form control to validate the element
     * @returns form control with errors object in array
     */
    static accountValidator(control: any) {
        control.setErrors(null);
        if (control.touched || control.dirty || control?.invalid) {
            if (!CommonUtil.valueExist(control.value)) {
                control.setErrors(ValidatiorUtil.accountRequired);
            }  else if (control.value?.accountNumber?.length < 10) {
                control.setErrors(ValidatiorUtil.accountInvalid);
            } else {
               console.log('No Account validation errors')
            }
        }
        return control.errors;
    }
    /**
     * Validate the amount pattern and atleast 0.01 and cannot be zero
     * @param control formControl to get the value and update
     * @returns map value
     */
    static isValidAmountCheck(control: FormControl): any {

        if (!CommonUtil.valueExist(control.value)) {
            return ValidatiorUtil.amountRequired;
        } else if (!ValidatiorUtil.amountPattern.test(control.value)) {
            return ValidatiorUtil.amountInvalid;
        } else if (control.hasError('min') || Number(control.value) < 0.01) {
            return ValidatiorUtil.amountAtleast;
        } else if (control.hasError('maxLength')) {
            return ValidatiorUtil.amountMaxLength;
        } else if (Number(control.value) >= 999999999999.00) {
            return ValidatiorUtil.amountMaxNumber;
        } else {
            return null ;
        }

    }
}
