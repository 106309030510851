import { Directive, ElementRef, HostListener } from '@angular/core';
import { debounce } from '../utilities/debounce.decorator';
import { CommonUtil } from '../utilities/common-util';

@Directive({
    selector: '[appAmountOnly]',
    standalone: false
})
export class AmountOnlyDirective {

  private readonly regex: RegExp = /^\d*\.?\d{0,2}$/;
  private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  
  constructor(private readonly el: ElementRef) { }

  @HostListener('input', ['$event'])
  @debounce(1000)
  onInputChange(event: any) {
    const inputValue = event.target.value;
    let validValue = inputValue.replace(/[^0-9\.]/g, '');
    this.preventDefault(inputValue, event);
    this.el.nativeElement.value = CommonUtil.toDecimalNumber(validValue, 2);
  }

 // @HostListener('keypress', ['$event'])
 // @debounce(500)
  onKeyPress(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;
     // Allow Backspace, tab, end, and home keys
     if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    this.preventDefault(current, event);
  }

  preventDefault(current: string, event: KeyboardEvent) {
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position)
    ].join('');

    if (next && !RegExp(this.regex).exec(String(next))) {
      event.preventDefault();
      console.log("PreventDefault is called ", event.defaultPrevented);
    }
  }
}