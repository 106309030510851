export const baseURL = 'https://universalportal-sso.qacitizensbank.com';
export const baseURL_LOCAL = 'http://localhost:8099' 
export const assetPrefix = "/balance-transfer-mfe/";
export const assetURL = `${baseURL}${assetPrefix}`;
export const environment = {
  production: false,
  title: "Make a Transfer",
  baseApiUrl: `${baseURL}/balance-transfer`,
  akamaiContentUrl: `${baseURL}/content/`,
  header: {
    1: { type: 'image', width: '200', alt: 'Citizens Commercial Banking', src: `${baseURL}/content/assets/CitizensCommercial_TM_Horz_RGB_HEX.svg` }
  },
  transmitUrl: `${baseURL}`,
  transmitJourneyName: 'balance_transfer',
  threatMetrixUrl: 'https://cdn17.citizensbank.com/fp/tags.js',
  threatMetrixProfileDomain: 'cdn17.citizensbank.com',
  threatMetrixProfileUrl: `${baseURL}/sso/dashboard`,
  orgId: '49qpgex6',
  citizensLogoImg: `${baseURL}/content/assets/CitizensCommercial_TM_Horz_RGB_HEX.svg`
 };
