<div [ngClass]="{'bt-alertArea': (message.type === 'success' || message.type === 'error' ||  message.type === 'warn'), 'new-message': message.type === 'token no longer needed for accessOPTIMA login'}"
    *ngIf="message">
    <div
        [ngClass]="{ 'bt-alert': message, 'bt-alert-success': (message.type === 'success' ), 'bt-alert-danger': message.type === 'error','bt-alert-warn': message.type === 'warn', 'alert-message': message.type === 'token no longer needed for accessOPTIMA login'}">
        <div class="bt-alertHeader">{{ message.title }}
            <div class="bt-align-close">
                <div class=" cbds-c-iconButton cbds-c-iconButton--contained bt-close" aria-label="close"
                    (click)="hide()"></div>
            </div>
        </div>
        <span class="bt-alertContent">
            <div [innerHTML]="message.text"></div>
        </span>
    </div>
</div>