<div class="container bal-transfer" *ngIf="displayCard">
    <div class="row">
        <div class="baltra-card">
            <div class="baltra-card-body">
                <div class="baltra-header-area">
                    <div class="award-checkmark "></div>
                    <button class="cbensightenevent" cbdata-type="button" cbdata-reason="view-make-transfer"
                        (click)="onclick()">Make a Transfer</button>
                </div>
                <p class="baltra-para-text">Transfer funds between your Citizens accounts.</p>
            </div>
        </div>
    </div>
</div>