import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../services/bt.alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: false
})
export class AlertComponent implements OnDestroy {
  private subscription: Subscription;
  message: any = '';
  constructor(private alertService: AlertService, private cdr: ChangeDetectorRef) {

    this.subscription = this.alertService.getMessage().subscribe((message: { text: any; }) => {
      if (!!message.text) {
        this.message = message;
        this.cdr.detectChanges();
        this.alertService.reset.set(false);
      } else {
        this.hide();
      }
    });
  }

  hide() {
    this.message = undefined;
    this.cdr.detectChanges();
    this.alertService.disabled.set(false);
    this.alertService.reset.set(true);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
