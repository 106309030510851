import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



const enum ConfirmationInputType {
  Resume = -1,
  Continue = 0,
  Cancel = 1,
}
@Injectable({
  providedIn: 'root'
})
export class TransmitUiHandler {

  constructor(private route: Router) {
    //  super(route);
    // sharedState.getApplicationDataSub('transmitInstance').subscribe(data => {
    //   console.log(data);
    //   this.transmitSDK = data.sdk;
    // })
  }

  handlePolicyRejection(
    title: string,
    text: string,
    buttonText: string,
    failureData: object,
    actionContext: com.ts.mobile.sdk.PolicyAction,
    clientContext: object | null
  ): Promise<com.ts.mobile.sdk.ConfirmationInput> {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.ConfirmationInput.create(
        ConfirmationInputType.Resume
      );
      resolve(response);
    });
  }

  controlOptionForCancellationRequestInSession(
    validOptions: Array<com.ts.mobile.sdk.ControlRequestType>,
    session: com.ts.mobile.sdk.UIAuthenticatorSession<com.ts.mobile.sdk.InputResponseType>
  ): Promise<com.ts.mobile.sdk.ControlRequest> {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.ControlRequest.create(
        com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator
      );
      resolve(response);
    });
  }

  startActivityIndicator(actionContext: any, clientContext: any) {
    console.log('start')
  }

  endActivityIndicator(actionContext: any, clientContext: any) {
    console.log('end')
  }

  handlePolicyRedirect = function (
    redirectType: any,
    policyId: any,
    userId: any,
    additionalParameters: any,
    clientContext: any
  ) {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy;
      const redirectResponse = com.ts.mobile.sdk.RedirectInput.create(response);
      resolve(redirectResponse);
    });
  };

  processJsonData(jsonData: any, actionContext: any, clientContext: any) {
    return new Promise((resolve, reject) => {
      var jsonFromTransmit = JSON.stringify(jsonData);
      console.debug(jsonFromTransmit);
      resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true));
   });
  }

  controlFlowCancelled = function (clientContext: any) {
    console.log("control flow cancelled");
  };

  controlFlowStarting = function (clientContext: any) {
    console.log("control flow starting");
  };

  controlFlowEnded = function (error: any, clientContext: any) {
    console.log("control flow ended");
  };

  controlFlowActionStarting = function (
    actionContext: any,
    clientContextl: any
  ) {
    console.log("Control flow action starting");
  };

  controlFlowActionEnded = function (
    error: any,
    actionContext: any,
    clientContext: any
  ) {
    console.log("Control flow action starting");
  };

  handleAuthenticatorUnregistration = function (
    authenticatorDescription: any,
    isPlaceholder: any,
    actionContext: any,
    clientContext: any
  ) {
    console.log("handle authentication unregistration");
  };

  selectAuthenticator = function (
    options: any,
    actionContext: any,
    clientContext: any
  ) {
    console.log("select authenticator");
  };

  selectAuthenticatorFallbackAction = function (
    validOptions: any,
    fallbackAuthenticator: any,
    session: any,
    actionContext: any,
    clientContext: any
  ) {
    console.log("select authenticator fallback action");
  };

  createPinAuthSession = function (
    title: any,
    username: any,
    pinLength: any
  ) {
    console.log("Pin Authenticator Session");
  };

  createPatternAuthSession = function (
    title: any,
    username: any,
    gridWidth: any,
    gridHeight: any
  ) {
    console.log("Pattern Authenticator Session");
  };

  createVoiceAuthSession = function (title: any, username: any) {
    console.log("Method not implemented.");
  };

  createSecurityQuestionAuthSession = function (
    title: any,
    username: any
  ) {
    console.log("create Security Question Auth Session");
  };

  createPlaceholderAuthSession = function (
    placeholderName: any,
    placeholderType: any,
    title: any,
    username: any,
    authenticatorConfiguredData: any,
    serverPayload: any
  ) {
    console.log("Method not implemented by demo application.");
  };

  createFido2AuthSession = function (title: any, username: any) {
    console.log("create Fido2 Auth Session");
  };

  getConfirmationInput = function (
    title: any,
    text: any,
    continueText: any,
    cancelText: any,
    actionContext: any,
    clientContext: any
  ) {
    console.log("get Confirmation Input");
  };

  getInformationResponse = function (
    title: any,
    text: any,
    continueText: any,
    actionContext: any,
    clientContext: any
  ) {
    console.log("get Information Response");
  };

  createMobileApproveAuthSession = function (
    title: any,
    username: any,
    instructions: any
  ) {
    console.log("create Mobile Approve Auth Session");
  };

  createTicketWaitSession = function (
    actionContext: any,
    clientContext: any
  ) {
    console.log("create Ticket Wait Session");
  };

  createTotpAuthSession = function (title: any, username: any) {
    console.log("create Totp Auth Session");
  };

  createAuthenticationConfigurationSession = function (
    userId: any
  ) {
    console.log("create Authentication Configuration Session");
  };

  createRegistrationPromotionSession = function (
    userId: any,
    actionContext: any
  ) {
    console.log("create Registration Promotion Session");
  };

  shouldIncludeDisabledAuthenticatorsInMenu = function (
    actionContext: any,
    clientContext: any
  ) {
    console.log("should Include Disabled Authenticators In Menu");
  };

  createScanQrSession = function (
    actionContext: any,
    clientContext: any
  ) {
    console.log("create Scan Qr Session");
  };

  createFingerprintAuthSession = function (
    title: any,
    username: any
  ) {
    console.log("create Fingerprint Auth Session");
  };

  createApprovalsSession = function (userId: any) {
    console.log("create Approvals Session");
  };

  createTotpGenerationSession = function (
    userId: any,
    generatorName: any
  ) {
    console.log("create Totp Generation Session");
  };

  createDeviceManagementSession = function (userId: any) {
    console.log("create Device Management Session");
  };

  createNativeFaceAuthSession = function (title: any, username: any) {
    console.log("create Native Face Auth Session");
  };

  createFaceAuthSession = function (title: any, username: any) {
    console.log("create Face Auth Session");
  };

  createDeviceBiometricsAuthSession = function (
    title: any,
    username: any
  ) {
    console.log("create Device Biometrics Auth Session");
  };

  localAuthenticatorInvalidated = function (description: any) {
    console.log("local Authenticator Invalidated");
  };

  setLogEnabled = function (isEnabled: any) {
    console.log("set Log Enabled");
  };
}
