import { Injectable, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { CommonUtil } from '../utilities/common-util';

@Injectable({
    providedIn: 'root',
})
export class AlertService  {
    private subject = new Subject<any>();
    public disabled = signal(false);
    public reset = signal(false);
    errorCodes = new Map<string,any>();
   
    constructor(private apiService: ApiService) {
     }

     init(): void {
        this.apiService.getDataFromCDN(this.apiService.ERROR_CONFIG_MANAGEMENT).subscribe(response => {   
            console.info("Load Error configuration json");  
            this.errorCodes = new Map(Object.entries(response));           
          });
     }
    find(errorCode: string): any{        ;
      return this.errorCodes.get(errorCode);
    }
  
    hide() {
        this.subject.next({ text: undefined });
        this.disabled.set(false);
        console.log(`Disabled : ${this.disabled()}`);
    }

    submitted( subject: string, message: string) {
        this.subject.next({ type: 'success', title: subject, text: message });
        this.disabled.set(true);
    }

    showMessage(message: string) {
        this.subject.next({
            type: 'token no longer needed for accessOPTIMA login',
            text: message,
        });
    }

    error(message: string) {
        const errorStatus = this.find(message);
        const title = CommonUtil.getOrDefault(errorStatus.title, 'Transfer Service is Unavailable');
        this.subject.next({ type: 'error',title: title, text: errorStatus.message });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}



