import { CompanyService } from './../../services/company.service';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';

import { Component, effect, ElementRef, OnInit, signal, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/bt.alert.service';
import { ApiService } from '../../services/api.service';
import { SpinnerService } from '../../services/spinner.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Account } from '../../models/account.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferRequest } from '../../models/transfer-request.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonUtil } from '../../utilities/common-util';
import { ValidatiorUtil } from '../../utilities/validators-util';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TransmitService } from '../../services/transmit-profiler/transmit.service';
import { environment } from '../../../environments/environment';
import { CurrencyPipe, DatePipe } from '@angular/common';

interface LINKCREDINFO {
  companyid: string;
  user_disabled: boolean;
  user_locked: any;
  userid: string;
  under_maintenance: boolean;
  applicationName: string;
}

const INSUFFICENT_ENTITLEMENT_ERROR = 'Insufficient entitlements for the requested operation';
const LIMIT_EXCEED_ERROR = 'Transaction limit exceeded';
@Component({
    selector: 'app-balance-transfer-submit',
    templateUrl: './balance-transfer-submit.component.html',
    styleUrl: './balance-transfer-submit.component.scss',
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }],
    standalone: false
})
export class BalanceTransferSubmitComponent implements OnInit {
  public balanceTransferForm: UntypedFormGroup;

  public header: any = environment.header;
  public ssoIDArr: any[] = [];
  ssoId: string;
  private readonly appName: string;
  public company: any;
  public companies: any[] = [];
  public ssoIdArr: any = [];
  public disableFields = true;
  public disableAccountSignal = signal(true);
  public disableOnAccountIsEmptySignal = signal(true);
  public citizensLogo = environment.citizensLogoImg;

  public _fromAccountData = new BehaviorSubject<any>(null);
  fromAccountData$ = this._fromAccountData.asObservable();

  public _toAccountData = new BehaviorSubject<any>(null);
  toAccountData$ = this._toAccountData.asObservable();

  public fromAccountAvailableBalanceMessage: string | null;
  public toAccountAvailableBalanceMessage: string | null;
  public fromAccountAvailableBalance: number | 0.00;
  public toAccountAvailableBalance: number | 0.00;

  private readonly NO_AVAILABLE_BALANCE: string = 'No Available balance';
  private readonly AVAILABLE_BALANCE: string = ' USD Available';
  private readonly TRANSFER_SUBMITTED_STATUS: string = 'Transfer Submitted - Approval needed in ';
  private readonly TRANSFER_SUBMITTED: string = 'Transfer Submitted';
 
  public holidays: Date[] = [];
  public minDate: Date = new Date();
  public maxDate: Date = CommonUtil.addDays(new Date(), 90);
  private readonly _transferDateInput = new Subject<any>();
  transferDateInput$ = this._transferDateInput.pipe(
    debounceTime(500), // Adjust the debounce time as needed
    distinctUntilChanged()
  );
  
  showError: boolean;
  errorMessage: string;
  numberOfDays: number = 1;
  disableButton = false;
  displaySpinner = false;
  // Profiler attributes
  tmxProfilingEnabled: boolean = false;
  private tmxUnivSessionId: string;

  @ViewChild('amountElement') amountElement: ElementRef;

  currencyPipe = new CurrencyPipe('en-US', 'USD');
 
  constructor(private readonly formBuilder: FormBuilder,
    private readonly modal: NgbModal,
    public alertService: AlertService,
    private readonly spinnerService: SpinnerService,
    private readonly apiService: ApiService,
    private readonly transmitService: TransmitService,
    private readonly companyService: CompanyService,
    public datepipe: DatePipe
  ) {

    effect(() => {
      this.resetAfterAlertClose();
    }
      , { allowSignalWrites: true }
    );
  }

  /**
   * Ng Initialize - Angular life cycle to initialize the component
   */
  ngOnInit(): void {
    this.getCompanyInfo();
    this.initHolidaysCalendar();
    this.createForm();
    this.initTMXDataProfiler();

    this.transferDateInput$
      .subscribe(value => {
        this.balanceTransferForm.get('transferDate')?.setValue(value);      
      });
    this.searchAccount('transferFrom', '');
  }

   /**
  * Get the Company and SSO ID information
  */
   private readonly getCompanyInfo: any = () => {
    this.companies = this.companyService.companiesSignal();
    console.log("number of companies entitled :: " + this.companies?.length );
    if(this.companies?.length){
      this.ssoId = this.companies[0]['ssoId'];
      this.company = this.companies[0];
    }
  }

  /**
   * Initialize the threat matrix profiling instanace invoking CDN to enable the txm profiler api
   */
  initTMXDataProfiler (): void {
    this.tmxUnivSessionId = '';
    this.tmxProfilingEnabled = false;

    this.apiService.getDataFromCDN(this.apiService.SERVICE_CONFIG_MANAGEMENT).subscribe(res => {
      this.tmxProfilingEnabled = CommonUtil.getOrDefault(res?.balanceTransfer?.tmxProfilerEnabled, false);
      console.log("Transmit TMX Profiler enabled :: ", this.tmxProfilingEnabled);
      if (this.tmxProfilingEnabled) {
        this.tmxUnivSessionId = this.transmitService.getCurrentSessionId();
        this.transmitService.initializeTransmit().subscribe((res) => {
          console.log('transmit initialize', res);
        });
      }
    });
  }

  /** Create form  */
  createForm(): void {
    const companyDropdownDisable: boolean = this.companies?.length == 1;
    this.balanceTransferForm = this.formBuilder.group({
      company: [{value: this.company, disabled: companyDropdownDisable}, Validators.required],
      transferFrom: ['', Validators.required],
      transferTo: ['', Validators.required],
      transferDate: [{value: this.minDate, disabled: true}, ValidatiorUtil.dateValidator],
      amount: [{ value: null, disabled: true }, [ValidatiorUtil.amountValidator, Validators.min(0.01), Validators.maxLength(15)]],
      comment: [{ value: '', disabled: true }]
    });

  }

  /**
   * Reset to default value as soon as ssoId change event trigger
   */
  resetDefaultValues() {
    this.errorMessage = '';
   
    this.balanceTransferForm.get('transferFrom')?.setValue('');
    this.balanceTransferForm.get('transferTo')?.setValue('');

    this.balanceTransferForm.get('amount')?.reset();
    this.balanceTransferForm.get('amount')?.setValue('');
    this.balanceTransferForm.get('amount')?.disable();
    
    this.balanceTransferForm.get('comment')?.setValue('');
    this.balanceTransferForm.get('comment')?.disable();

    this.balanceTransferForm.get('transferDate')?.reset();
    this.balanceTransferForm.get('transferDate')?.setValue(this.minDate);
    this.balanceTransferForm.get('transferDate')?.disable();

    this.toAccountAvailableBalanceMessage = '';
    this.fromAccountAvailableBalanceMessage = '';
    this.disableAccountSignal.set(true);
    this.disableOnAccountIsEmptySignal.set(true);
    this.resetAccountList();
    this.searchAccount('transferFrom', '');
  }

  /**
   * Reset the Account list to empty list
   */
  resetAccountList() {
    this._fromAccountData.next(null);
    this._toAccountData.next(null);
  }

  /**
   * Select company and reset to default values as soon as ssoId change event trigger
   */
  onCompanyChange(): void {
    const selectedCompany = this.balanceTransferForm.get('company')?.value;
    console.log('On Company Change reset default page', selectedCompany);
    this.ssoId = selectedCompany.ssoId;
    this.company = selectedCompany;
    this.resetDefaultValues();
    this.alertService.hide();
  }

  /**
   * Perform data pick as user input or change any event in UI
   * @param event to capture user activity and object
   */
  onDateInput(event: MatDatepickerInputEvent<Date>): void {
    this._transferDateInput.next(event.value);
  }

  /**
   * Initalize Calendar date range invoking BTL Holiday API and Set max Date
   */
  initHolidaysCalendar: any = () => {
    this.showError = false;
    this.showSpinner();
    this.apiService.holidayAPI(this.ssoId).subscribe({
      next: (response: any) => this.handleHolidayDatesResponse(response),
      error: (error: any) => {
        this.hideSpinner();
        console.error(error);
        this.errorMessage = `**Unable to display accounts, please check the balances**`;
      }
    });
    this.spinnerService.hideSpinner();
  }

  //Handle reponse for the holiday dates recieved from API
  private handleHolidayDatesResponse(response: any) {
    this.displaySpinner = false;
    if (response.status === 200) {
      this.showError = false;
      const responseData = response.data;
      responseData?.holidays.forEach((element: any) => {
        console.log(element);
        let date = new Date(element);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        this.holidays.push(date);
      });
      this.maxDate = CommonUtil.addDays(new Date(), parseInt(responseData.forwardCutoff));
    }
    if (response.status !== 200) {
      this.showError = true;
    }
  }

  // Filter date by holidays retruned from API
  holidayFilter: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) {
        return false;
      }     
      for (let holiday of this.holidays) {
        if (CommonUtil.isDatesEqual(holiday, date)) {
          console.log("HolidayDate : %d :: Date : %d", holiday.toDateString(), holiday.toDateString())
          return false;
        }
      }
      return true;
    };


  /*****************************************************
    Dropdown Account list implementation
  *****************************************************/

  /**
   * Create request body to retrieve Transfer to account list
   * @param searchEvent serach text event for use input
   */
  searchAccount(transferType: string, searchEvent: any) {
    const isFromAccount = transferType === 'transferFrom';
    const filterAccount = this.balanceTransferForm.get(isFromAccount ? 'transferTo' : 'transferFrom')?.value;
    const search = searchEvent?.searchCriteria;
    let criteria = search ? search.trim() : '';
    const offset = searchEvent?.offset | 1;

    const excludedAccounts = filterAccount ? [
      {
        accountNumber: filterAccount.accountNumber,
        bankCode: filterAccount.bankCode
      }
    ] : null;
    const requestData = {
      ssoId: this.ssoId,
      entitlementType: 'TRANSFER',
      searchCriteria: criteria,
      excludedAccounts: excludedAccounts,
      rowOffset: offset,
      pageLimit: 10
    }
    //Reset the from account list to empty and then call
    this.searchAccountAPI(requestData, isFromAccount);
  }

  /**
   * Set form control for transfer from
   * @param event value change event if there is any change in the dropdown
   */
  valueChangedFromAccount(e: any) {
    this.alertService.hide();
    console.log(`Value changed method is: ${e}`);    
    this.balanceTransferForm.get('transferFrom')?.setValue(e);   
    this.balanceTransferForm.get('transferTo')?.setValue('');
    this.balanceTransferForm.get('transferTo')?.enable();
    this.toAccountAvailableBalanceMessage = '';
    this.fromAccountAvailableBalanceMessage = '';
    this.onAccountChange(true);
    this.disableAccountSignal.set(false);
    //If user changes the value, then load search to accounts
    this._toAccountData.next(null);
    this.searchAccount('transferTo', '');
  }

  /**
   * Set form control for transfer to
   * @param event value change event if there is any change in the dropdown
   */
  valueChangedToAccount(e: any) {
    console.log(`Value changed method is: ${e}`);
    this.balanceTransferForm.get('transferTo')?.setValue(e);
    ValidatiorUtil.accountValidator(this.balanceTransferForm.get('transferTo'));    
    this.onAccountChange(false);
  }

  /**
  * Get transfer account object upon change on dropdown and call available balance API
  */
  onAccountChange(isFromAccountRequest: boolean) {
    if (isFromAccountRequest) {
      this.fromAccountAvailableBalanceMessage = '';
    } else {
      this.toAccountAvailableBalanceMessage = '';
    };
    const transferFromAccountCrtl = this.balanceTransferForm.get('transferFrom');
    const transferToAccountCrtl = this.balanceTransferForm.get('transferTo');
    const requestData = {
      accountNumber: (isFromAccountRequest ? transferFromAccountCrtl : transferToAccountCrtl)?.value?.accountNumber,
      routingNumber: (isFromAccountRequest ? transferFromAccountCrtl : transferToAccountCrtl)?.value?.routingNumber,
      accountType: (isFromAccountRequest ? transferFromAccountCrtl : transferToAccountCrtl)?.value?.bankAccountType
    }

    ValidatiorUtil.accountValidator (isFromAccountRequest ? transferFromAccountCrtl : transferToAccountCrtl);
    //If any account changes reset the amount field
    this.balanceTransferForm.get('amount')?.reset();
    this.balanceTransferForm.get('amount')?.setValue('');
    
    this.retrieveBalanceAPI(requestData, isFromAccountRequest);
    if (CommonUtil.valueExist(transferFromAccountCrtl?.value) && CommonUtil.valueExist(transferToAccountCrtl?.value)) {
      this.disableFields = false;
      this.disableOnAccountIsEmptySignal.set(false);
      
    }
  }

  /**
   * SEARCH ACCOUNT LIST :Search Account API - invoke server REST API
  *
  * @param requestData contains search object to retrieve the list of objects
  * @param isFromAccountRequest check if the request is for fromAccount list or To AccountList  */

  searchAccountAPI(requestData: any, isFromAccountRequest: boolean) {
    this.showError = false;
    this.showSpinner();
    console.log(this.apiService.searchAccountListAPI(requestData, this.ssoId));
    // Replace this URL with your actual API endpoint
    this.apiService.searchAccountListAPI(requestData, this.ssoId).subscribe({
      next: (response: any) => this.handleSearchAccountResponseData(response, isFromAccountRequest),
      error: (error: any) => {
        this.hideSpinner();
        this.alertService.error(error.status);
      },
      complete: () => {
        console.log('Completed calling Search Account API');
        this.spinnerService.hideSpinner();
      },
    });
    this.spinnerService.hideSpinner();
  }

  handleSearchAccountResponseData(response: any, isFromAccountRequest: boolean) {
    this.hideSpinner();
    const data = response?.data?.accountListResponseList;
    let searchData = {
      data: CommonUtil.getOrDefault(data, []),
      totalOffset: CommonUtil.getOrDefault(response?.data?.totalRows, 0),
    }
    if (isFromAccountRequest) {
      this._fromAccountData.next(searchData);
    } else {
      this._toAccountData.next(searchData);
    }
    this.showError = response.status !== 200;
  }

  /**
   * RETRIEVE BALANCES : Invoke server side REST API and retrieve the balances for the account and its type
   * @param requestData request body to retrieve the balances
   * @param isFromAccountRequest the request to retrieve balance is for From Account or To Account
   */
  retrieveBalanceAPI(requestData: any, isFromAccountRequest: boolean): void {
    this.showError = false;

    this.showSpinner();
    this.apiService.balanceAPI(requestData, this.ssoId).subscribe({
      next: (response: any) => {
        this.handleRetrieveBalanceResponse(response, isFromAccountRequest);
      },
      error: (error: any) => {
        this.hideSpinner();
        this.alertService.error(error.status);
      }
    });
    this.spinnerService.hideSpinner();

  }

  /**
   * Handle the Balance retrieval reponse object and status
   * @param response API response returned from server
   * @param isFromAccountRequest the request from account or to account list
   */
  private handleRetrieveBalanceResponse(response: any, isFromAccountRequest: boolean) {
    this.hideSpinner();
    let availableBalance = response?.data?.availableBalance ?? 0.0;
    let message = response.status !== 200 ? this.NO_AVAILABLE_BALANCE :  this.currencyPipe.transform( availableBalance, 'USD', true ) + this.AVAILABLE_BALANCE;
    if (isFromAccountRequest) {
      this.fromAccountAvailableBalance = availableBalance;
      this.fromAccountAvailableBalanceMessage = message;
    } else {
      this.toAccountAvailableBalance = availableBalance;
      this.toAccountAvailableBalanceMessage = message;
    }
    this.showError = response.status !== 200;
    console.log("Balance :: ", isFromAccountRequest, message);
  }

  /**
   * INITIATE TRANSFER : Invoke server side REST API and intiate transfer
  */
  onTransferSubmit(): void {
    
    this.validateBeforeSubmit();
    if (!this.balanceTransferForm.valid) {
      console.warn("The form is not valid");     
      this.balanceTransferForm.markAllAsTouched();     
      return;
    }

    if (this.tmxProfilingEnabled) {
      const universalId = sessionStorage.getItem('universalId');
      const profilingData = this.transferRequestBody();
      this.transmitService.authenticate(universalId, profilingData, this.tmxUnivSessionId)
        .then((res) => {
          console.log('transmit res', res);
          this.onTransfer();
        }).catch((error) => {
          this.spinnerService.hideSpinner();
          console.error('transmit error', error);
          let tmxErrorCode = this.findAttributeRecursively(error, 'numcode');
          if (!tmxErrorCode) {
            tmxErrorCode = 3999;
          }
          this.alertService.error('TMX-' + tmxErrorCode);
        });
    } else {
      this.onTransfer();
    }
  }

  /**
  * INITIATE TRANSFER : Invoke server side REST API and intiate transfer
  */
  onTransfer(): void {
    if (!this.balanceTransferForm.valid) {
      console.warn("The form is not valid");
      this.balanceTransferForm.markAllAsTouched();
      return;
    }

    this.showError = false;
    this.showSpinner();

    const data = this.transferRequestBody();
    this.apiService.intiateTransfer(data, this.ssoId).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.showError = false;
          this.hideSpinner();
          this.displaySuccessMessage(response?.data);
        }

        if (response.status !== 200) {
          this.showError = true;
        }
      },
      error: (error: any) => {
        this.showError = true;
        this.hideSpinner();
        this.errorMessage = `Cannot make a transfer due to failure`;
         let btlErrorCode = this.findAttributeRecursively(error, 'code');
          let errorCodeStatus = error?.status;
      
          if(btlErrorCode === 'BDR4000'){           
            if ( error.message.toLowerCase().includes(INSUFFICENT_ENTITLEMENT_ERROR.toLowerCase()) ) {
              errorCodeStatus = 'BTL-4001';
            } else if (error.message.toLowerCase().includes(LIMIT_EXCEED_ERROR.toLowerCase()) ) {
              errorCodeStatus = 'BTL-4002';  
            } else {
              errorCodeStatus = 'BTL-4000';
            }
            console.error("Remote error BTL - ", errorCodeStatus , error?.message);
          }
          this.alertService.error(errorCodeStatus);
      }
    });
    this.spinnerService.hideSpinner();
  }

  // Get the account object removing attributes from the object and send to API over network
  private getAccountDetails(type: string) {
    const transferAccount: Account = this.balanceTransferForm.get(type)?.value;
    delete (transferAccount as any).routingNumber;
    delete (transferAccount as any).bankAccountType;
    return transferAccount;
  }

  /**
   * Create Transfer Request Body
   */
  private transferRequestBody(): TransferRequest {
    const transferFromAccount = this.getAccountDetails('transferFrom');
    const transferToAccount = this.getAccountDetails('transferTo');
    const transferDate = this.balanceTransferForm.get('transferDate')?.value;
    const tDate = this.datepipe.transform(transferDate, 'yyyy-MM-ddThh:mm:ss', 'en_US');  
    const amount = this.balanceTransferForm.get('amount')?.value;
    const amountWithTwoDecimal =  CommonUtil.toDecimalNumber(String(amount), 2);   
    this.balanceTransferForm.get('amount')?.setValue(amountWithTwoDecimal);
    const comment = this.balanceTransferForm.get('comment')?.value; 
    return new TransferRequest(tDate,  Number(amountWithTwoDecimal), transferFromAccount, this.fromAccountAvailableBalance, transferToAccount, this.toAccountAvailableBalance, comment);
  }
  // Display output with the formated Text
  private displaySuccessMessage(data: any) {    
   const amount =  CommonUtil.toDecimalNumber(String(data.amount), 2);
    let message = `<table>
         <tr><td>ID</td> <td>: ${data.transactionId} </td></tr>
         <tr><td>From Account</td><td>: ${data.fromAccount.accountNumber} - ${data.fromAccount.accountName} </td></tr>
         <tr><td>To Account</td><td>: ${data.toAccount.accountNumber} - ${data.toAccount.accountName} </td></tr>
         <tr><td>Transfer Date</td><td>: ${data.transferDate} </td></tr>
         <tr><td>Amount</td><td>: ${amount} </td></tr> </table>\n`;
    let title = '';
    if (data.status.errorCode == 0 && CommonUtil.valueExist(data.status?.message)) {
      title = CommonUtil.getOrDefault(data.status?.message[1], data.status?.message[0]);
      if (RegExp(title).exec(this.TRANSFER_SUBMITTED)) {
        title = this.TRANSFER_SUBMITTED_STATUS + this.company?.applicationTitle;
      }
    }
    this.alertService.submitted(title, message);
    this.resetDefaultValues();
  }

  displayErrorMessage(message: any) {
    this.alertService.error(message);
  }

  onBack(): void {
    this.modal.dismissAll();
  }

  /**
   * Reset the default page if any user close the alert message in the alert component
   */
  private resetAfterAlertClose() {
    if (!this.disableOnAccountIsEmptySignal()) {
      this.balanceTransferForm.get('comment')?.enable();
      this.balanceTransferForm.get('amount')?.enable();
      this.balanceTransferForm.get('transferDate')?.enable();
    }
  }

  private hideSpinner(){
    this.spinnerService.hideSpinner();
    this.displaySpinner = false;
  }

  private showSpinner() {
    this.spinnerService.setText('Please wait...');
    this.spinnerService.showSpinner();
    this.displaySpinner = true;
  }

  hasError(controlName: string) : boolean {
    const control = this.balanceTransferForm.get(controlName);
    return (control?.invalid && control?.touched && (control.errors?.['invalid'] || control.errors?.['required']));
  }

  validateBeforeSubmit() {
    const transferDateControl = this.balanceTransferForm.get('transferDate');
    if (transferDateControl?.enabled) {
      transferDateControl?.markAsTouched();
      ValidatiorUtil.dateValidator(transferDateControl);
      if (CommonUtil.holidayFilter(transferDateControl?.value, this.holidays)) {
        transferDateControl?.setErrors(ValidatiorUtil.futureDateSelect)
      };
    }

    const amountControl = this.balanceTransferForm.get('amount')
    if (amountControl?.enabled) {
      amountControl?.markAsTouched();
      ValidatiorUtil.amountValidator(amountControl);
    }
  }

  findAttributeRecursively(obj: any, attribute: string): any | undefined {
    if (!obj || typeof obj !== 'object') {
      return undefined;
    }
    if (obj.hasOwnProperty(attribute)) {
      return obj[attribute];
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
        const result = this.findAttributeRecursively(obj[key], attribute);
        if (result !== undefined) {
          return result;
        }
      }
    }
    return undefined;
  }

  onMouseEnter() {
    const control = this.balanceTransferForm.get('amount');
    if (!control?.value && control?.enabled) {
      control?.setValue('0.00');
    }
  }

  onMouseLeave() {
    const control = this.balanceTransferForm.get('amount');
    if (control?.value === '0.00' && control?.enabled) {
      control?.setValue('');
    }
  }

  disableArrowKeys(event: KeyboardEvent) {
    if (event.key === "ArrowUp" || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
}

